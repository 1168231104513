import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import { Row, Col, Image } from "react-bootstrap"
import profilePic from "../img/davenport.jpg"
import aboutStyles from "./about.module.scss"

const AboutPage = () => {
  let today = new Date().getHours()
  let greeting = null

  if (today < 12) {
    greeting = "Good morning"
  } else if (today < 18) {
    greeting = "Good afternoon"
  } else {
    greeting = "Good evening"
  }

  return (
    <div>
      <Layout>
        <Head title="About" />
        <Row>
          <Col className="d-flex justify-content-center" fluid="true" md>
            <Image
              className="mb-5"
              src={profilePic}
              alt="Davenport and I"
              height="400px"
              roundedCircle
            />
          </Col>
          <Col md>
            <h1 className="pb-3">{greeting},</h1>
            <p className={aboutStyles.bio}>
              Hello there, my name is Tanner B. Roof and I've been making
              websites for about as long as I can remember. It was always just a
              hobby; something I never thought I'd see a career out of.
              Developing sites (in Notepad) for friends and my own video game
              modding websites in the early 2000's was sort of how it all got
              started. Eventually, I went on to the University of South Carolina
              and graduated with a B.A. in Journalism &amp; Mass Communication
              in 2014. After a slew of PR jobs that I didn't necessarily love, I
              figured it was time to get back in the saddle and rekindle the
              flame with something I was truly passionate about—{" "}
              <i>web development.</i>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={aboutStyles.bio}>
              I began taking night classes at a (now defunct) coding bootcamp
              called{" "}
              <a
                href="https://www.coursereport.com/schools/the-iron-yard"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Iron Yard
              </a>{" "}
              in late 2016. From there, I took one of the biggest risks of my
              life and quit my current job to attend there full time. After a
              rigorous three months of 9 to 5 courses, I emerged as a (somewhat)
              more competent developer, having learned and worked with
              frameworks such as React for the first time. It was a far cry from
              my early bare bones 2000's HTML and CSS days.
            </p>
            <p className={aboutStyles.bio}>
              During the midsts of the pandemic, I worked for a decentralized
              clinical trial company called{" "}
              <a
                href="https://www.medable.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Medable, Inc.
              </a>{" "}
              I helped maintain and develop their clinical trial web
              applications. It was truly rewarding to know that my work was
              directly benefiting those participating in clinical trials for
              COVID-19 therapies. While I was sadly laid off, I wouldn't trade
              the two years there for anything, as I gained an exorbitant amount
              of experience while working with professionals in the industry all
              around the world. I was especially proud of helping contribute to
              the Globalization initiative to ensure our products supported 100+
              different locales and languages.
            </p>
            <p>
              Before that, I worked for the Soldier Support Institute at Fort
              Jackson in South Carolina. It is there that I've learned to hone
              my skills as a professional developer over three years time. I
              utilized all kinds of frameworks (React, Vue, Cordova, just to
              name a few) and have launched applications to both Apple's App
              Store and Google's Play Store. During my down time there, I
              continually took online courses to improve my skill sets. I also
              enjoyed participating in hackathons around the area.
            </p>
            <p className={aboutStyles.bio}>
              I've been a developer for over five years now and still strive to
              improve each and every day. I think that's what I enjoy most about
              this industry— there's always something new on the horizon.
            </p>
          </Col>
        </Row>
      </Layout>
    </div>
  )
}

export default AboutPage
